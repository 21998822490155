<template>
  <div id="inventory-pin-batch-search">
    <breadcrumb-base :breadcrumb="breadcrumb" title="PinAndBatchSearch">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>
    <div class="vx-row w-full">
      <div class="w-full md:w-1/3">
      </div>
      <div class="w-full md:w-1/3">
        <vx-card>
          <div class="">
            <vs-input
              class="w-full"
              v-model="batchNo"
              :placeholder="$t('BatchNo')"
              :label="$t('BatchNo')">
            </vs-input>

            <div class="text-center mt-4">
              <vs-avatar color="dark" :text="$t('OR')"/>
<!--              <p>-->
<!--                <span class="p-2 bg-dark rounded-full text-white">{{ $t('OR') }}</span>-->
<!--              </p>-->
            </div>

            <vs-input
              class="w-full"
              v-model="pinNo"
              :placeholder="$t('Pin')"
              :label="$t('Pin')">
            </vs-input>

            <div class="py-2"/>
            <div class="flex justify-center">
              <vs-button @click="searchBatchNo">{{$t('Search')}}</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="w-full md:w-1/3">
      </div>
    </div>
    <div class="py-2"/>
    <vs-table-modified class="flex-1-1 w-full" v-if="cardDetails"
                       :data="cardDetails.result"
    >
      <template slot="thead">
        <vs-th >{{ $t('Status') }}</vs-th>
        <vs-th >{{ $t('PinFrom') }}</vs-th>
        <vs-th >{{ $t('SupplierName') }}</vs-th>
        <vs-th >{{ $t('Product') }}</vs-th>
        <vs-th >{{ $t('Denomination') }}</vs-th>
        <vs-th>{{ $t('BuyingDate') }}</vs-th>
        <vs-th>{{ $t('SellingDate') }}</vs-th>
        <vs-th >{{ $t('BatchNo') }}</vs-th>
        <vs-th>{{ $t('Pin') }}</vs-th>
        <vs-th >{{ $t('Seller') }}</vs-th>
        <vs-th >{{ $t('Organisation') }}</vs-th>
<!--        <vs-th >{{ $t('SupplierCompany') }}</vs-th>-->
<!--        <vs-th >{{ $t('SupplierDiscount') }}</vs-th>-->
<!--        <vs-th >{{ $t('SubOrganisation') }}</vs-th>-->
      </template>
      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="text-center flex justify-center w-full" v-if="tr.pin_from === 'Reclamation'">
              <div class="vs-col w-full text-center flex-col flex justify-center">
                <vs-chip :color="getReclaimStatusColor(tr.reclaimed_status)" class="user-status">
                  {{ $t('Reclamation') }}
                </vs-chip>
                <p>{{ getReclaimStatusText(tr.reclaimed_status) | title }}</p>
              </div>
            </div>
            <div class="text-center flex justify-center" v-else>
              <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                  getStatusText(tr.status) | title
                }}
              </vs-chip>
            </div>
<!--            <vs-chip :color="tr.status === 0 ? 'danger' : 'success'">{{-->
<!--                tr.status === 0 ? $t('UnSold') : $t('Sold')-->
<!--              }}-->
<!--            </vs-chip>-->
          </vs-td>
          <vs-td>
            <p class="product-name font-medium"
               style="word-break: normal !important;"
            >{{ tr.pin_from }}</p>
          </vs-td>
          <vs-td v-if="tr.supplier">
            <p class="product-name font-medium"
               style="word-break: normal !important;"
            >{{ tr.supplier }}</p>
          </vs-td>
          <vs-td v-else>
            <p class="product-name font-medium"
               style="word-break: normal !important;"
            >{{ tr.pin_from }}</p>
          </vs-td>
          <vs-td>
            <div class="flex flex-col">
              <p class="product-name font-medium"
                 style="word-break: normal !important;"
              >{{ tr.product_name }}</p>
              <p class="product-name font-medium"
                 style="word-break: normal !important;"
              >{{ tr.product_type | uppercase }}</p>
            </div>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium"
               style="word-break: normal !important;"
            >{{ tr.denomination | germanNumberFormat }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium"
               style="max-width: 100px; word-break: normal !important;"
            >{{ tr.buying_date | dateFormat }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium"
               style="max-width: 100px; word-break: normal !important;"
            >{{ tr.selling_date | dateFormat }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">
              {{ tr.batch }}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">
              {{ tr.pin }}</p>
          </vs-td>
          <vs-td>
            <div class="vs-col" v-if="tr.child_organisation.sub_child_organisation">
              <p class="product-name font-medium truncate">{{ tr.child_organisation.sub_child_organisation.organisation_name }}</p>
              <p class="product-name font-medium truncate">{{ tr.child_organisation.sub_child_organisation.customer_id }}</p>
            </div>
          </vs-td>
          <vs-td>
            <div class="vs-col">
              <p class="product-name font-medium truncate">{{ tr.child_organisation.organisation_name }}</p>
              <p class="product-name font-medium truncate">{{ tr.child_organisation.customer_id }}</p>
            </div>
          </vs-td>

<!--          <vs-td>-->
<!--            <p class="product-name font-medium truncate">-->
<!--              {{ tr.supplier }}</p>-->
<!--          </vs-td>-->
<!--          <vs-td>-->
<!--            <p class="product-name font-medium truncate">-->
<!--              {{ tr.supplier_company }}</p>-->
<!--          </vs-td>-->
<!--          <vs-td>-->
<!--            <p class="product-name font-medium truncate">-->
<!--              {{ tr.supplier_discount }}</p>-->
<!--          </vs-td>-->
<!--          <vs-td>-->
<!--            <div class="vs-col">-->
<!--              <p class="product-name font-medium truncate">{{ tr.child_organisation.sub_child_organisation.organisation_name }}</p>-->
<!--              <p class="product-name font-medium truncate">{{ tr.child_organisation.sub_child_organisation.customer_id }}</p>-->
<!--            </div>-->
<!--          </vs-td>-->
        </vs-tr>
        </tbody>
      </template>
    </vs-table-modified>
  </div>
</template>

<script>
export default {
  name: "GiftCardBatchSearch",
  data() {
    return {
      batchNo: '',
      pinNo: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Pin and Batch Search', i18n: 'PinAndBatchSearch', active: true },
      ],
      cardDetails: null,
    }
  },
  methods: {
    getReclaimStatusText(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return this.$t('InReview')
      if (status === 1) return this.$t('InProgress')
      if (status === 2) return this.$t('InProgress')
      if (status === 3) return this.$t('IssueCreditNote')
      if (status === 5) return this.$t('Reject')
      return ''
    },
    getReclaimStatusColor(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'danger'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'purple'
      if (status === 5) return 'primary'
      return ''
    },
    getStatusText(status) {
      // 0 - unsold, 1 - sold, 2 - Unpublished
      if (status === 0) return this.$t('UnSold')
      if (status === 1) return this.$t('Sold')
      if (status === 2) return this.$t('Unpublished')
      return ''
    },
    getStatusColor(status) {
      // 0 - unsold, 1 - sold, 2 - Unpublished
      if (status === 0) return 'danger'
      if (status === 1) return 'success'
      if (status === 2) return 'warning'
      return ''
    },
    searchBatchNo() {
      this.$vs.loading()
      let payload = {}
      if(this.batchNo) {
        payload.batch = this.batchNo;
      }

      if(this.pinNo) {
        payload.pin = this.pinNo;
      }

      return this.$store.dispatch('inventorySearch/searchPinBatch', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.cardDetails = data;
        })
        .catch((error) => {
          let msg = ''
          try {
            msg = error.response.data.message || error.message
          } catch (err) {
            msg = err.message
          }
          this.$vs.loading.close()
          this.errorFetching = true
          this.batchNo = '';
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg || 'Not found',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  }
}
</script>

<style scoped lang="scss">
#inventory-pin-batch-search {
  .product-image {
    /*max-width: 200px;*/
    max-height: 40px;
    object-fit: cover;
  }
}
.card-data {
  img {
    height: 100px;
  }
}
</style>
